:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
}

.styles-module_tooltip__mnnfp {
  visibility: hidden;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 90%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  will-change: opacity, visibility;
}

.styles-module_fixed__7ciUi {
  position: fixed;
}

.styles-module_arrow__K0L3T {
  position: absolute;
  background: inherit;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

.styles-module_no-arrow__KcFZN {
  display: none;
}

.styles-module_clickable__Bv9o7 {
  pointer-events: auto;
}

.styles-module_show__2NboJ {
  visibility: visible;
  opacity: var(--rt-opacity);
}

/** Types variant **/
.styles-module_dark__xNqje {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.styles-module_light__Z6W-X {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.styles-module_success__A2AKt {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}

.styles-module_warning__SCK0X {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}

.styles-module_error__JvumD {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}

.styles-module_info__BWdHW {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}
